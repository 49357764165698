<template>
  <div style="height: 943px" class="loading" v-if="news_data === undefined && !news_error">
    <Loading />
  </div>
  <section id="news" class="blog-section ptb-50" v-else-if="news_data">
    <div class="container">
      <div class="section-title">
        <h2>Latest News</h2>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-8">
          <carousel
            class="blog-slider owl-theme"
            :nav="false"
            :margin="30"
            :responsive="{0:{items:1},600:{items:2},992:{items:2}}"
          >
            <div class="blog-item" v-for="item in news_data.data" :key="item.id">
              <div class="image">
                <a :href="item.link" target="_blank" rel="noreferrer">
                  <DefaultImage :id="item.image" :height="'235px'" />
                </a>
              </div>
              <ul class="post-meta">
                <li>
                  <i class="fa fa-calendar"></i>
                  {{ item.date ? moment(item.date) : "-" }}
                </li>
              </ul>
              <div class="content">
                <h3>
                  <a :href="item.link" target="_blank" rel="noreferrer">
                    {{item.name}}
                  </a>
                </h3>
              </div>
            </div>
          </carousel>
        </div>

        <div class="col-lg-4 mt-lg-0 mt-5 position-relative">
          <div class="widget-buttons" v-if="showWidgets">
            <button @click="showFacebook = true; showInstagram = false; showTwitter = false" :class="{'active': showFacebook}">
              <i class="fa fa-facebook"></i> Facebook
            </button>
            <button @click="showFacebook = false; showInstagram = true; showTwitter = false" :class="{'active': showInstagram}">
              <i class="fa fa-instagram"></i> Instagram
            </button>
            <button @click="showFacebook = false; showInstagram = false; showTwitter = true" :class="{'active': showTwitter}">
              <i class="fa fa-twitter"></i> Twitter
            </button>
          </div>
          <div class="w-100" v-if="showWidgets">
            <Instagram v-if="showInstagram" />

            <div v-if="showFacebook" class="facebook-widget d-flex justify-content-center" >
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPeaceJamFoundation&tabs=timeline&width=350&height=433&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                width="350"
                height="433"
                style="border:none;overflow:hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true">
              </iframe>
            </div>
            <div v-if="showTwitter" class="twitter-section">
               <Timeline id="PeaceJam" sourceType="profile" :options="{ tweetLimit: '2' }"/>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import moment from "moment";
  import carousel from 'vue-owl-carousel';
  import DefaultImage from "./DefaultImage";
  import Loading from "./Loading";
  import useSWRV from "swrv";
  import api from "../services/api";
  import Instagram from "./Instagram";
  import { Timeline } from 'vue-tweet-embed'

  export default {
    name: "BlogArea",
    setup() {
      let {
        data: news_data,
        error: news_error,
        isValidating: news_isValidating
      } = useSWRV(() => `items/news?status=published&start=date&sort=-date&offset=0&limit=6`, api.fetcher);

      return {
        news_data,
        news_error,
        news_isValidating
      }
    },
    data() {
      return {
        showFacebook: true,
        showInstagram: false,
        showTwitter: false,
        showWidgets: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      moment(date) {
        return moment(date).format("DD MMM YYYY");
      },
      handleScroll() {
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        scrollPosition = scrollPosition + (window.innerHeight / 2);
        let funFactsArea = document.getElementById('news');

        if (funFactsArea) {
          if(scrollPosition >= funFactsArea.offsetTop && !this.showWidgets) {
            this.showWidgets = true;
          }
        }
      }
    },
    components: {
      Instagram,
      Timeline,
      Loading,
      DefaultImage,
      carousel
    }
  }
</script>

<style scoped lang="scss">
  .blog-section {
    padding-bottom:20px;
  }

  .section-title h2 {
    font-size:35px;
    margin:0;
  }

  .blog-item .content h3 {
    height: 82px;
  }

  .twitter-section{
    height: 433px;
    overflow: scroll;
  }

  .widget-buttons {
    position: absolute;
    top: -33px;
    width: 94%;
    text-align: center;

    button {
      border: none;
      background: #e7e7e7;
      padding: 2px 17px 2px 10px;
      font-size: 15px;

      i {
        margin-right: 5px;
      }

      &.active {
        background: #7b6ff2;
        color: #fff;
      }
    }
  }

  @media (max-width: 991px) {
    .widget-buttons {
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
