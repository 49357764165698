<template>
  <div data-mc-src="657a6de9-67d9-4c55-b17f-2d2f972d807d#instagram"></div>
</template>

<script>
  import $ from "jquery";
  import instagram from "../services/instagram";
  export default {
    name: "Instagram",
    created() {
      $(instagram).appendTo(document.body);
    }
  }
</script>

<style scoped>

</style>
